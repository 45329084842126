@import '~@qbueno/do-css-framework/scss/do-css/settings';

// The naming convention used for variables is the [ZenCSSProperties]
//
// Some of the prefixes used:
// * b = border
// * bg = background;
// * c = color
// * ff = font-family
// * fz = font-size

// ** GLOBAL variables **
// -----------------------------------------------------------------------------

// Colors (use colors variables only inside settings)
// -----------------------------------------------------------------------------

$c-cerulean: #0090da;
$c-cerulean-light: #add3ec;
$c-cerulean-hover: #05a2f3;
$c-cerulean-active: #0fa6df;
$c-aliceblue: #e6f3fb;
$c-lavender: #e1effe;
$c-auxblue: #f7fcfe;
$c-dark-soft: #595959;
$c-dark-ultralight: #cccccc;
$c-gray: #848484;
$c-gray-light: #b2b2b2;
$c-mine: #3d3d3d;
$c-mine-light: #5c6979;
$c-porcelain: #f2f4f5;
$c-aquahaze: #f7f9fa;
$c-papaya: #ffeed1;
$c-very-light-pink: #f8e0de;
$c-watermelon: #fe4f64;
$c-form-error: #f44336;
$c-error: #d0021b;
$c-gainsboro: #dce0e4;
$c-whitesmoke: #eff0f2;
$c-alabaster: #fafafa;
$c-yellowgreen: #73cf19;
$c-mediumspringgreen: #00d7a2;
$c-white: #fdfeff;
$c-black: #000;

// Fonts
// -----------------------------------------------------------------------------
$ft-regular: 'lato-regular';
$ft-bold: 'lato-bold';
$ft-bold-italic: 'lato-bolditalic';
$ft-semibold: 'lato-bold';
$ft-italic: 'lato-italic';
$ft-thin: 'lato-regular';
$ft-light: 'lato-regular';

$ff-base: $ft-regular, Arial, sans-serif;
$ff-regular: $ff-base;
$ff-bold: $ft-bold, Arial, sans-serif;
$ff-bold-italic: $ff-base;
$ff-semibold: $ff-bold;
$ff-italic: $ff-base;
$ff-thin: $ff-base;
$ff-light: $ff-base;

// Font sizes
// -----------------------------------------------------------------------------
$fz-xxsmall: 12px;
$fz-xsmall: 13px;
$fz-small: 14px;
$fz-base: 15px;

$lh-base-headings: 1.35;
$lh-base-body: 1.35;

$fz-medium: 18px;
$fz-medium-desktop: 18px;

$fz-large: 20px;
$fz-large-desktop: 20px;

$fz-xlarge: 24px;
$fz-xlarge-desktop: 24px;

$fz-xxlarge: 24px;
$fz-xxlarge-desktop: 30px;

// Gutters
// -----------------------------------------------------------------------------
$gt-base: 8px;
$gt-semilarge: ($gt-base * 3);

// Paths
// -----------------------------------------------------------------------------
// 1. Define root path used in the function: Asset URL builder
// 2. Add fonts files to this folder
// 3. Path required to find images on qb-frontend
//

$pt-base: './'; // 1
$pt-fonts: '/src/skin/fonts/'; // 2
$pt-images: '/src/skin/images/'; // 3
$pt-icons: '/src/skin/icons/';
$pt-notification-icon: '/src/skin/images/notifications/';
$pt-passwordinput-button: '/src/skin/images/passwordinput/';
$pt-radiolist-payment: '/src/skin/images/radiolist/';
$pt-reminder: '/src/skin/images/reminder/';

// Mediaqueries
// -----------------------------------------------------------------------------
$vp-xxlarge: 'min-width: 1280px';

// Widths
// -----------------------------------------------------------------------------
$wd-form: 500px;

// Z-indexs
// -----------------------------------------------------------------------------
$z-loansummary: 8000;
$z-loading: 1000;

// ** SPECIFIC variables **
// -----------------------------------------------------------------------------

// Buttons
// -----------------------------------------------------------------------------
// Button base
$brr-button: 100px;

// Button
$br-button: $br-small solid $c-cerulean-light;
$ff-button: $ff-bold;

// Button primary
$c-button-primary: $c-cerulean;
$br-button-primary: $br-small solid $c-cerulean;
$ff-button-primary: $ff-regular;
$c-button-primary-active: $c-cerulean;
$br-button-primary-active: $br-small solid $c-cerulean;
$c-button-primary-hover: $c-cerulean-hover;
$br-button-primary-hover: $br-small solid $c-cerulean-hover;
$c-button-primary-visited: $c-cerulean;
$br-button-primary-visited: $br-small solid $c-cerulean;

// Button link
$td-button-link: none;
$ff-button-link: $ff-regular;
$c-button-link-text: $c-cerulean;
$c-button-link-visited-text: $c-button-link-text;

// Button sizes
$fz-button-small: $fz-small;
$pd-button-small: 8px 16px 8px 16px;
$lh-button-small: 1.2;

$fz-button-medium: $fz-small;
$pd-button-medium: 8px 120px 8px 120px;
$lh-button-medium: 1.5;

$fz-button-large: $fz-base;
$pd-button-large: 12px 24px 12px 24px;
$lh-button-large: 2;

$fx-button-xlarge: $fz-medium;
$pd-button-xlarge: 14px 28px 14px 28px;
$lh-button-xlarge: 2;

// Borders
// -----------------------------------------------------------------------------
$b-button-radius: 5px;

// Content text
// -----------------------------------------------------------------------------
$fz-text: $fz-base;
$ff-text: $ff-base;
$gt-text: $gt-medium;
$c-text: $c-mine-light;

// Definition-list
// -----------------------------------------------------------------------------
$bg-definitionlist-title: rgba(212, 219, 222, 0.23);
$c-definitionlist-title: $c-gray;
$fz-definitionlist-dsktp-title: $fz-small;
$ff-definitionlist-title: $ff-regular;
$c-definitionlist-desc: $c-mine;

// FieldsPreview
// -----------------------------------------------------------------------------
$c-fieldspreview-title: $c-cerulean;
$c-fieldspreview-desc: $c-gray;
$brc-fieldspreview: $c-dark-ultralight;

// Forms
// -----------------------------------------------------------------------------
$br-form-heading2: 1px solid rgba(0, 0, 0, 0.2);
$brr-form-field: 3px;
$c-form-field: $c-mine;
$c-form-focus: $c-cerulean;

$fz-form-label: $fz-small;
$ff-form-label: $ft-regular;

$fz-form-field: $fz-base;

$c-form-label-disabled: $c-dark-light;
$ff-form-field-disabled: $ff-italic;

$ff-form-field: $ff-regular;

$c-form-field-placeholder: $c-gainsboro;

// Form-radiolist
// -----------------------------------------------------------------------------
$bt-form-radiolist: 0;
$bb-form-radiolist-item: 1px solid $c-dark-ultralight;

// Headings
// -----------------------------------------------------------------------------
$ff-headings: $ft-regular, Arial, sans-serif;

$fz-heading1: $fz-xlarge;
$fz-heading1-desktop: $fz-xlarge-desktop;
$c-heading1: $c-mine;
$fz-heading2: $fz-large;
$fz-heading2-desktop: $fz-large-desktop;

// Links
// -----------------------------------------------------------------------------

$c-link: $c-cerulean;

// Loading
// -----------------------------------------------------------------------------
$br-loading-spinner-animated: 12px solid $c-cerulean-hover;

// Notifications
// -----------------------------------------------------------------------------
$ff-notification-text: $ff-regular;
$fz-notification-text: $fz-small;
$c-notification-info: rgba(0, 144, 218, 0.11);
$c-notification-info-heading: $c-gray;
$c-notification-info-text: $c-gray;
$c-notification-info-link: $c-gray;

$c-notification-warning: $c-papaya;
$c-notification-warning-heading: $c-gray;
$c-notification-warning-text: $c-gray;
$c-notification-warning-link: $c-gray;
$c-notification-error: $c-very-light-pink;
$c-notification-error-text: $c-mine;
$c-notification-success: $c-yellowgreen;

$tp-notification-icon: 9px;
$brr-notification-alert: 5px;

// Panel
// -----------------------------------------------------------------------------
$c-panel-default: $c-alabaster;
$gt-panel: 20px;
$gt-panel-large: 20px;

// Progress bar
// -----------------------------------------------------------------------------
$h-progressbar: 10px;
$c-progressbar-value: #03a9f4;
$c-progressbar-value-text: $c-cerulean;
$h-progressbar-value: 10px;

// Reminder
// -----------------------------------------------------------------------------
$wd-reminder-icon: 24px;
$ht-reminder-icon: 24px;
$fz-reminder: $fz-base;

// Summary
// -----------------------------------------------------------------------------
$c-summary-desc: $c-gray;
$c-summary-title: $c-dark-soft;
$ta-summary: left;
$ff-summary-title: $ff-base;
$c-summary-desc: $c-dark-soft;
$ff-summary-desc: $ff-base;
$br-summary-divider: 1px solid $c-whitesmoke;

// ** custom PROJECT SPECIFIC components **
// -----------------------------------------------------------------------------
//

// Header
$wd-header-logo-image: 153px;
$ht-header-logo-image: 35px;
$wd-header-logo-image-desktop: 211px;
$ht-header-logo-image-desktop: 48px;
$wd-initialsize: 32px;
$ht-header: 60px;
$ht-header-desktop: 82px;

// Footer
$ht-footer: 72px;
$ht-footer-desktop: 56px;

// Menu
$ht-item: 53px;
$wd-initial: 50px;

// Screen sizes
$screen-s: 480px;
$screen-m: 570px;
$screen-l: 768px;
$screen-xl: 980px;

@mixin hide-on-screen($screen-min-width) {
  display: none;
  @media (min-width: $screen-min-width) {
    display: block;
  }
}

@mixin show-on-screen($screen-min-width) {
  display: none;
  @media (max-width: $screen-min-width) {
    display: block;
  }
}

$sc-s: 'min-width: ' + $screen-s !default;
$sc-m: 'min-width: ' + $screen-m !default;
$sc-l: 'min-width: ' + $screen-l !default;
$sc-xl: 'min-width: ' + $screen-xl !default;

.hide-s {
  @include hide-on-screen($screen-s);
}

.hide-m {
  @include hide-on-screen($screen-m);
}

.hide-l {
  @include hide-on-screen($screen-l);
}

.hide-xl {
  @include hide-on-screen($screen-xl);
}

.show-s {
  @include show-on-screen($screen-s);
}

.bold {
  font-family: $ft-bold;
}

.italic {
  font-family: $ft-italic;
}

.text {
  &-primary {
    color: $c-cerulean;
  }
  &-crossedOut {
    text-decoration: line-through;
    margin-right: 10px;
  }
}

.Link {
  outline: none;
  border: none;
}

@import '~@qbueno/do-css-framework/scss/do-css/mixins/utils';

.LoginLayout {
  &-top {
    @include font($fz-small);
    display: inline-block;
    width: 100%;
    text-align: right;

    @media ($vp-large) {
      position: absolute;
      top: -32px;
      right: 0;
    }
  }

  &-header {
    @include font($fz-xlarge);
    display: block;
    border-bottom: 1px solid $c-dark-ultralight;
    padding: 0 0 $gt-base 0;
    margin-bottom: $gt-semilarge;
  }

  &-content {
    color: $c-gray;
    padding-bottom: $gt-medium;
  }
}
