.Icon {
  &-group {
    pointer-events: all;
    &:hover {
      cursor: pointer;
      .Icon-error {
        fill: $c-watermelon;
      }
    }
  }
}
